<template>
    <div class="about-container">
        <a-card title="关于我们管理">
            <a-form-model ref="form" :rules="formRules" :model="formData" :label-col="{span:2}" :wrapper-col="{span:12}">
                <a-form-model-item required label="企业介绍：" prop="introduce">
                    <div class="editor">
                        <Toolbar style="border-bottom: 1px solid #ccc;" :editor="editorRef" :defaultConfig="editorConfig.toolbarConfig" :mode="mode" />
                        <Editor  v-model="formData.introduce" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
                    </div>
                </a-form-model-item>
                <a-form-model-item required label="公司图片：" prop="img">
                    <upload ref="uploadCompany" action="" upload-length="6" @uploadChange="handleChange"></upload>
                </a-form-model-item>
                <a-form-model-item required label="荣誉资质：" prop="license">
                    <upload ref="uploadHonor" action="" upload-length="12" @uploadChange="handleHonorUploadChange"></upload>
                </a-form-model-item>
                <a-form-model-item required label="企业邮箱：" prop="email">
                   <a-input v-model="formData.email" placeholder="请输入邮箱"></a-input>
                </a-form-model-item>
                <a-form-model-item required label="企业电话：" prop="phone">
                    <a-input v-model="formData.phone" placeholder="企业电话"></a-input>
                </a-form-model-item>
                <a-form-model-item required label="企业地址：" prop="address">
                    <a-input v-model="formData.address" placeholder="请输入企业地址"></a-input>
                </a-form-model-item>
            </a-form-model>
            <a-button type="primary" :loading="loading" @click="save"><a-icon type="save"></a-icon>保存</a-button>
        </a-card>

    </div>
</template>

<script>
import upload from "@/components/upload.vue";
import { toolbarConfig, MENU_CONF } from '@/utils/wangeditors.js'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import {saveOrEditAbout, saveOrEditBanner, selectAboutData} from '@/api/apis'
import handleRequest from "@/utils/handleRequest";
import {validEmail, validPhone} from "@/utils/validator";
export default {
    name: "aboutOption",
    components:{upload,Editor, Toolbar},

    data() {
        return {
            loading:false,
            mode:'default',
            editorRef: null,
            editorConfig: {
                placeholder: '请输入内容...',
                //配置工具栏
                toolbarConfig: toolbarConfig,
                MENU_CONF: MENU_CONF,
            },
            formData:{
                introduce:'',
                email:'',
                phone:'',
                address:'',
                img:[],
                license:[]
            },
            formRules:{
                introduce:[{required:true,message:'请输入企业介绍',trigger:'blur'}],
                email:[{validator:(rules,value,callback)=>{
                        if(validEmail(value)){
                           return true
                        }else{
                            callback('邮箱格式不正确')
                        }
                    },trigger:'blur'}],
                phone:[{validator:(rules,value,callback)=>{
                        if(validPhone(value)){
                            return true
                        }else{
                            callback('电话格式不正确')
                        }
                    },trigger:'blur'}],
                address:[{required:true,message:'请输入企业地址',trigger:'blur'}],
                img:[{validator:(rules,value,callback)=>{
                    if(this.formData.img.length){
                        return true
                    }else{
                        callback('请上传至少一张图片')
                    }
                    },trigger:'blur'}],
                license:[{validator:(rules,value,callback)=>{
                        if(this.formData.license.length){
                            return true
                        }else{
                            callback('请上传至少一张图片')
                        }
                    },trigger:'blur'}],
            }
        }
    },
    mounted() {
        this.getAboutData();
    },
    methods: {
        async getAboutData(){
            const data = handleRequest(await selectAboutData())
            data.img = JSON.parse(data.img)
            data.license = JSON.parse(data.license)
            this.formData = data
            this.showUpLoaderDefaultList()
        },
        async save(){
            this.loading = true
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.formData.img = JSON.stringify(this.formData.img)
                    this.formData.license = JSON.stringify(this.formData.license)
                    handleRequest(await saveOrEditAbout(this.formData),res=>{
                        if(res.code == 200){
                            this.$message.success('操作成功')
                            this.loading = false
                            this.getAboutData()
                        }
                    })
                } else {
                    console.log('验证错误');
                    console.log(this.formData)
                    this.loading = false
                    return false;
                }
            });

        },
        handleCreated(editor) {
            this.editorRef = editor // 记录 editor 实例
        },
        handleChange(imgList){
            this.formData.img = imgList
        },
        handleHonorUploadChange(imgList){
            this.formData.license = imgList
        },
        showUpLoaderDefaultList(){
            this.$nextTick(()=>{
                this.$refs.uploadCompany.imgList = this.formData.img
                this.$refs.uploadHonor.imgList = this.formData.license
            })
        },
    },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
::v-deep .w-e-scroll{
    min-height: 300px;
}
.editor{
    z-index: 999999;
    width: 600px;
    min-height: 300px;
    border: 1px solid #8d8d8d;
}
.about-container{
    padding: 20px;
    overflow: auto;
}
</style>
