export function validEmail(email) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return reg.test(email)
}

export function validPhone(phone) {
    const reg = /^0\d{2,3}-\d{7,8}$/
    const subReg = /^[1][3,4,5,7,8][0-9]{9}$/
    if(reg.test(phone)){
        return reg.test(phone)
    }else{
        return subReg.test(phone)
    }
}
